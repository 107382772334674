import React from 'react';
import './print.css'; // Assuming you have your styles here
import profileImage from "assets/init100.png";

const Invoice = React.forwardRef((props, ref) => {
  const { invoiceData } = props;

  return (
    <div ref={ref} className="invoice-container">
      <div className="invoice-header">
        <div className="invoice-top">
          <img src={profileImage} alt="not"></img>
        </div>
        <div className="invoice-left">
          <p><strong>Emri:</strong> <span>{invoiceData.name}</span></p>
          <p><strong>Adresa:</strong> <span>{invoiceData.address}</span></p>
          <p><strong>Telefoni:</strong> <span>{invoiceData.phone || "___________"}</span></p>
        </div>
        <div className="invoice-right">
          <p><strong>Data pageses:</strong> <span>{invoiceData.paymentDate}</span></p>
        </div>
      </div>

      <table className="invoice-table">
        <thead>
          <tr>
            <th>Nga</th>
            <th>Deri</th>
            <th>Identifikuesi</th>
            <th>Përshkrimi</th>
            <th>Paguar?</th>
            <th>Vlera</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.items && invoiceData.items.map((item, index) => (
            <tr key={index}>
              <td>{item.dateStart}</td>
              <td>{item.dateEnd}</td>
              <td>{item.typeIdentifier}</td>
              <td>{item.description}</td>
              <td>{item.isPaid?"PO":"JO"}</td>
              <td>{item.totalAmount.toFixed(2)}</td>
            </tr>
          ))}
            <tr>
            <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
            <td style={{ fontWeight: 'bold' }}>{invoiceData.totalPayment}</td>
          </tr>
        </tbody>
      </table>

      <div className="invoice-footer">
        <p><strong>Dorezoi:</strong> {invoiceData.operatorName}</p>
        <p><strong>SHENIM:</strong> {invoiceData.note}</p>
      </div>

      <div className="svn-footer">
        <p><strong>SOLVENDA | solvenda.org</strong></p>
      </div>
    </div>
  );
});

export default Invoice;
