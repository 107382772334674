import React, { useEffect } from "react";
import { useCreatePacketMutation } from "features/packets/packetsApiSlice";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import {
  useGetMainServicesListMutation,
  useGetServiceListQuery,
} from "features/services/servicesApiSlice";
import {
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import FlexBetween from "components/FlexBetween";
import CloseAddFooter from "./CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "../styled/CustomMenuItem"; // Import the custom styled MenuItem

const PacketForm = ({ onClose, refetch }) => {
  const [formData, setFormData] = useState({
    name: "",
    price: 0,
    regionIds: [],
    serviceId: 0,
    description: "",
    mainServiceId: 0,
    bouquets: [],
    isBouquet: false,
    accessCriteria:"",
    accessBits: [],
  });
  const { showNotification } = useServerResponseNotifier();

  const [selectedService, setSelectedService] = useState("");
  const [isExtream, setIsExtream] = useState(false);
  const [isDvbc,setIsDvbc]=useState(false);
  const [createPacket, { isLoading, isError, isSuccess }] =
    useCreatePacketMutation();
  const [getMainServicesList] = useGetMainServicesListMutation();

  const [showMainServices, setShowMainServices] = useState(false);
  const [mainServices, setMainServices] = useState([]);

  const {
    data: regionListData,
    isLoading: isRegionLoading,
    refetch: refetchRegions,
  } = useGetRegionListQuery();

  const {
    data: serviceListData,
    isLoading: isServiceLoading,
    refetch: refetchServices,
  } = useGetServiceListQuery();

  const handleMainServicesToggle = () => {
    setShowMainServices(!showMainServices); // Toggle the state
  };

  useEffect(() => {
    refetchRegions();
    refetchServices();
    // refetchMainServices();  //hiqe kur te kaloj ne serviceId endpointi
  }, []);


  const loadServiceNames = (service) => {
    const serviceName = service.name.toLowerCase();
    setIsExtream(serviceName.includes("extream") || serviceName.includes("code"));
    setIsDvbc(serviceName.includes("dvbc"));
  };

  const handleChange = async (e) => {

    const { name, value } = e.target;

    if (name === "regionIds") {
      if (value.includes("all")) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          regionIds: prevFormData.regionIds.length === regionListData.length ? [] : regionListData.map((region) => region.id),
        }));
        return;
      }
    }

    if (name === "serviceId") {
      const service = serviceListData.find((svc) => svc.id === value);
      setSelectedService(service);
      loadServiceNames(service);
      await loadMainServies(value);
    }

    if (name === "bouquets" && isExtream) {
      if (value.includes("all")) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          bouquets: prevFormData.bouquets.length === mainServices.length ? [] : mainServices.map((serv) => serv.id),
          isBouquet: true,
        }));
        return;
      }
    }

    if (name === "accessBits" && isDvbc) {
      let updatedAccessBits;
      if (value.includes("all")) {
        updatedAccessBits = formData.accessBits.length === 32 ? [] : Array.from({ length: 32 }, (_, index) => index);
      } else {
        updatedAccessBits = value;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        accessBits: updatedAccessBits,
        bouquets:updatedAccessBits,
      }));
      calculateAccessCriteria(updatedAccessBits);
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const loadMainServies = async (serviceId) => {
    const { data: payload } = await getMainServicesList(serviceId).catch(
      (err) => {
        showNotification(err.data.Message, err.data.StatusCode);
      }
    );
    if (payload) {
      setMainServices(payload || []);
    }
  };


  const calculateAccessCriteria = (selectedBits) => {
    let sum = selectedBits.reduce((acc, bit) => acc + Math.pow(2, bit), 0);
    const maxSum = Math.pow(2, 32) - 1; // Maximum value for 32 bits
    if (sum > maxSum) {
      sum = maxSum;
    }
    let hexValue = sum.toString(16).toUpperCase().padStart(8, '0'); // Ensure 8-character format with leading zeros
    setFormData((prevFormData) => ({
      ...prevFormData,
      accessCriteria: hexValue,
    }));
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createPacket(formData)
      .unwrap()
      .then((payload) => {
        showNotification("Packet created succesfully!", 200);
      })
      .catch((err) => {
        console.error("Failed to create user:", err);
        showNotification(err.data.Message, err.data.StatusCode);
      });

    await refetch();
    onClose();
  };

  return (
    <Box component="form">
      <FlexBetween>
        <TextField
          required
          fullWidth
          label="Packet Name"
          margin="normal"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />

        <TextField
          required
          fullWidth
          label="Price"
          margin="normal"
          name="price"
          value={formData.price}
          onChange={handleChange}
        />
      </FlexBetween>

      <TextField
        required
        fullWidth
        label="Description"
        margin="normal"
        name="description"
        value={formData.description}
        onChange={handleChange}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="region-label">Region</InputLabel>
        <Select
          required
          labelId="region-label"
          id="region"
          multiple
          value={formData.regionIds}
          onChange={handleChange}
          name="regionIds"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "200px",
                overflow: "auto",
              },
            },
          }}
        >
          <CustomMenuItem value="all">All</CustomMenuItem>

          {regionListData &&
            regionListData.map((region, index) => (
              <CustomMenuItem key={index} value={region.id}>
                {region.name}
              </CustomMenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="service-label">Service</InputLabel>
        <Select
          labelId="service-label"
          name="serviceId"
          value={formData.serviceId}
          onChange={handleChange}
          required
        >
          {serviceListData &&
            serviceListData.map((service, index) => (
              <CustomMenuItem key={index} value={service.id}>
                {service.name}
              </CustomMenuItem>
            ))}
        </Select>
      </FormControl>

      {!isExtream && !isDvbc && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="mainService-label">Packet service:</InputLabel>
          <Select
            labelId="mainService-label"
            required
            name="mainServiceId"
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            {mainServices &&
              mainServices.map((service, index) => (
                <CustomMenuItem key={index} value={service.id}>
                  {service.name}
                </CustomMenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      {isExtream && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Bouquete service:</InputLabel>
          <Select
            required
            multiple
            value={formData.bouquets}
            name="bouquets"
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <CustomMenuItem value="all">All</CustomMenuItem>
            {mainServices &&
              mainServices.map((service, index) => (
                <CustomMenuItem key={index} value={service.id}>
                  {service.name}
                </CustomMenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      {isDvbc && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Access Bits:</InputLabel>
            <Select
              required
              multiple
              value={formData.accessBits}
              name="accessBits"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "200px",
                    overflow: "auto",
                  },
                },
              }}
            >
              <CustomMenuItem value="all">All</CustomMenuItem>
              {Array.from({ length: 32 }, (_, index) => (
                <CustomMenuItem key={index} value={index}>
                  {index}
                </CustomMenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            required
            fullWidth
            label="Access Criteria"
            margin="normal"
            name="accessCriteria"
            value={formData.accessCriteria}
            onChange={handleChange}
          />
        </>
      )}

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default PacketForm;