import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateRadiusUserMutation } from "features/userPackets/userPacketsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const CreateRadiusForm = ({onClose,formData, currentUser,refetchList}) => {

  const [createRadiusUser]=useCreateRadiusUserMutation();
  const {showNotification}=useServerResponseNotifier();

  const [internetData, setInternetData] = useState({
    username: "",
    password: "",
    ipAddress: "",
    description: "",
  });


  const [username,setUsername]=useState("");
  const [dataForUpdate,setDataUpdate]=useState(null)


  useEffect(() => {
    const generatedUsername = generateData();
    setUsername(generatedUsername);
  }, [internetData]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if(name==="username")
    {
      setUsername(value);
    }

    setInternetData({
      ...internetData,
      [name]: value,
    });

    setDataUpdate({
      ...dataForUpdate,
      [name]:value,
    });
  };

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...internetData,
      ["username"]:username,
      ["password"]:"1234"
    });
  }, [formData,currentUser]);


//   useEffect(() => {
//     console.log("Current dataForUpdate: ", dataForUpdate);
// }, [formData]);

  const generateData = () => {
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };



  const handleSubmit=async (e)=>{
    e.preventDefault();
  //  console.log("Data being sent:", dataForUpdate);
    await createRadiusUser(dataForUpdate).unwrap()
    .then(showNotification("Service for user created succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

   await onClose();
   await refetchList();
  }
  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        defaultValue={generateData()}
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        defaultValue="1234"  // krijo logjiken qe ta marre nga default config passwordin
        label="Password"
        margin="normal"
        name="password"
        variant="outlined" 
        onChange={handleChange}
      />
      {/* <TextField
        fullWidth
        label="MAC"
        margin="normal"
        name="macAddress"
        onChange={handleChange}
      /> */}
      <TextField
        fullWidth
        label="IP"
        margin="normal"
        name="ipAddress"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="description"
        onChange={handleChange}
      />

<CloseAddFooter onClose={onClose} onClick={handleSubmit} />

    </Box>
  );
};

export default CreateRadiusForm;
