import { Box, IconButton } from "@mui/material";
import TableComp from "components/TableComp";
import AddServiceActions from "components/forms/actions/AddServiceActions";
import { useGetPaymentsForOperatorQuery } from "features/payments/paymentsApiSlice";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PaginationTable from "components/PaginationTable";
import PaymentHeader from "./PaymentHeader";
import OperatorPaymentHeader from "./OperatorPaymentHeader";

const OperatorPayments = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetPaymentsForOperatorQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);


  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      flex: 0.5,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 0.5,
    },
    {
      field: "typeIdentifier",
      headerName: "Type Identifier",
      flex: 0.5,
    },
    {
      field: "monthsPaid",
      headerName: "Months",
      flex: 0.2,
    },
    {
      field: "periodPaid",
      headerName: "Period Paid",
      flex: 0.9,
    },
    {
      field: "packetName",
      headerName: "Packet",
      flex: 0.5,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
    },
    {
      field: "isPaid",
      headerName: "Paid",
      flex: 0.2,
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 0.3,
    },
    
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <OperatorPaymentHeader
        refetch={refetchData}
        pageNumber={pageNumber}
        pageSize={pageSize} 
        setFilters={setFilters}
        totalAmount={tableData.totalAmount}
      />

      <PaginationTable
        title={"Payments"}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
      />
    </Box>
  );
};

export default OperatorPayments;
