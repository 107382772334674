import React,{useEffect} from "react";
import { useGetOperatorListQuery, useGetOperatorsPaginationQuery } from "features/operators/operatorsApiSlice";
import Header from "components/Header";
import { Box, TextField } from "@mui/material";
import OperatorForm from "components/forms/OperatorForm";
import OverlayForm from "components/OrverlayForm";
import TableComp from "components/TableComp";
import { useState } from "react";
import OperatorActions from "components/forms/actions/OperatorActions";
import EditRegionForm from "components/forms/editForms/EditRegionForm";
import EditOperatorForm from "components/forms/editForms/EditOperatorForm";
import PaginationTable from "components/PaginationTable";
import AddNewHeader from "components/forms/headers/AddNewHeader";
import DeleteOperatorForm from "components/forms/deleteForms/DeleteOperatorForm";

const Operator = () => {
const [pageNumber, setPageNumber] = useState(0);
const [pageSize, setPageSize] = useState(10);
const [tableData, setTableData] = useState([]);
const [filters, setFilters] = useState([]);
const [editForm, setEditForm] = useState(false);
const [deleteForm, setDeleteForm] = useState(false);
const [showForm, setShowForm] = useState(false);
const [searchTerm, setSearchTerm] = useState("");

const toggleDeleteForm=()=>setDeleteForm(!deleteForm);
const openEditForm = () => setEditForm(true);
const closeEditForm = () => setEditForm(false);
const handleOpenForm = () => setShowForm(true);
const handleCloseForm = () => setShowForm(false);
const [currOperator,setCurrOperator]=useState("");

const {
  data,
  isLoading,
  refetch: refetchList,
} = useGetOperatorsPaginationQuery({
  pageNumber: pageNumber + 1,
  pageSize,
  filters: filters,
});

useEffect(() => {
  if (data) {
    setTableData(data);
  }
}, [data]);

useEffect(() => {
  refetchList({ pageNumber, pageSize, filters });
}, [pageNumber, pageSize]);

const handlePaginationModelChange = (model) => {
  setPageNumber(model.page);
  setPageSize(model.pageSize);
};

const handleFilter = async (e) => {
  const { name, value } = e.target;
  console.log("here", value);
  setSearchTerm(value);
};

useEffect(() => {
  const timerId = setTimeout(async () => {
    if (searchTerm) {
      let newFilters = [];
      newFilters.push({
        columnName: "Name",
        value: `${searchTerm}`,
        operation: 0,
      });
      setFilters(newFilters);
    } else {
      setFilters([]);
    }
  }, 700);
  return () => {
    clearTimeout(timerId);
  };
}, [searchTerm]);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "regions",
      headerName: "Regions",
      flex: 0.5,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Numer",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "left",
      renderCell: (params) => {

        const editClick = () => {
          setCurrOperator(params.id);
          openEditForm();
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setCurrOperator(params);
        };

        return (
        <OperatorActions editClick={editClick} deleteClick={deleteClick} />
        )

      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <AddNewHeader
        openForm={handleOpenForm}
        title={"Operators"}
      />

      <TextField
        sx={{ height: "32px", margin: "0",".MuiOutlinedInput-input":{ padding:"8px" }}}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleFilter}
        label="Search Operator"
        margin="normal"
        name="name"
      />

      <PaginationTable
        handleOpenForm={handleOpenForm}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"700px"}
      />

      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={<OperatorForm onClose={handleCloseForm} refetchList={refetchList}/>}
          maxWidth={"770px"}
        />
      )}


    {editForm && ( <OverlayForm 
      formComponent={<EditOperatorForm operatorId={currOperator}  
      onClose={closeEditForm}  
     refetch={refetchList}
       />}
       maxWidth={"770px"}
      formTitle={"Edit operator"}
       />
    )}

{deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteOperatorForm
              operator={currOperator}
              onClose={toggleDeleteForm}
              refetch={refetchList}
            />
          }
          formTitle={"DELETE OPERATOR"}
        />
      )}

    </Box>
  );
};

export default Operator;
