import { Box, TextField } from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useUpdateUPStalkerMutation } from "features/userPackets/userPacketsApiSlice";
import React, { useEffect, useState } from "react";
import CloseAddFooter from "../CloseAddFooter";

const EditStalkerForm = ({
  onClose,
  formData,
  refetchList,
  stalkerData,
  userPacketId,
  refetchDetails,
}) => {
  const [updateStalkerUser] = useUpdateUPStalkerMutation();
  const { showNotification } = useServerResponseNotifier();

  const [stalker, setStalker] = useState({
    username: stalkerData?.username || "",
    macAddress: stalkerData?.macAddress || "",
    description: formData?.description || "",
  });

  useEffect(() => {
    setStalker({
      username: stalkerData?.username || "",
      macAddress: stalkerData?.macAddress || "",
      description: formData?.description || "",
    });
  }, [stalkerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(name==="macAddress")
    {
      let newValue=value;
      newValue=newValue.toUpperCase();
      newValue = newValue.replace(/:/g, '');
      newValue = newValue.slice(0, 12);
      newValue = newValue.match(/.{1,2}/g)?.join(':') || '';
      setStalker((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
      return;
    }
    
    setStalker((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
      ...stalker,
    };

    await updateStalkerUser({
      id: userPacketId,
      request: updatedData,
    }).unwrap()
      .then(() => {
        showNotification("Service for user updated successfully!", 200);
        refetchDetails();
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        value={stalker.username}
        variant="outlined"
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        value={stalker.macAddress}
        label="MAC"
        margin="normal"
        name="macAddress"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        value={stalker.description}
        label="Description"
        margin="normal"
        name="description"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />

      <CloseAddFooter
        footerLabel={"Edit"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default EditStalkerForm;
