import React, { useRef } from "react";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import {
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Checkbox, FormGroup, FormControlLabel
} from "@mui/material";
import { useState } from "react";
import FlexBetween from "components/FlexBetween";
import CloseAddFooter from "./CloseAddFooter";
import { useCreateOperatorMutation } from "features/operators/operatorsApiSlice";
import { useGetAllRightsMutation, useGetAllRightsQuery } from "features/operatorRights/operatorRightsApiSlice"; 
import Header from "components/Header";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "components/styled/CustomMenuItem";

const OperatorForm = ({ onClose, refetchList }) => {
  const { data: regionList, isLoading: isRegionLoading } =
    useGetRegionListQuery();
  const [createOperator, { isLoading, isError, isSuccess }] =
    useCreateOperatorMutation();


  const { data: rightsList, isLoading: isRightsLoading } =
    useGetAllRightsQuery();


  const { showNotification } = useServerResponseNotifier();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    regionIds: [],
    email: "",
    address: "",
    password: "",
    confirmPassword: "",
    rightsIds:[],
  });



  const handleSubmit = async (e) => {
    e.preventDefault();
    await createOperator(formData)
      .unwrap()
      .then((payload) => {
        showNotification("Operator created succesfully",200);
      })
      .catch((error) => {
        showNotification(error.data.Message,error.data.StatusCode);

      });
    await refetchList();
    onClose();
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'rightsCheckBox') {
      console.log(rightsList);
      console.log(value);
      const right = rightsList.find(r => r.id == value);
      if (right) handleRightsChange(right.id, checked);
    } 
    else {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    }
    validateField(name, value);
  };


  
  const handleRightsChange = (rightId, isChecked) => {
    setFormData(prevFormData => {
      const newRightsIds = isChecked
        ? [...prevFormData.rightsIds, rightId]
        : prevFormData.rightsIds.filter(id => id !== rightId);

      return { ...prevFormData, rightsIds: newRightsIds };
    });
  };

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    regionIds: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateField = (name, value) => {
    let error = "";
    if (name === "firstName" || name === "lastName") {
      error = value ? "" : "This field is required";
    }
    if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      error = emailRegex.test(value) ? "" : "Invalid email address";
    }
    if (name === "password") {
      const minPasswordLength = 8; // you can set your own length
      error =
        value.length >= minPasswordLength
          ? ""
          : `Password must be at least ${minPasswordLength} characters`;
    }
    if (name === "confirmPassword") {
      error = formData.password === value ? "" : "Passwords do not match";
    }

    if (name === "regionIds") {
      if (value.length < 1) {
        error = "Need to have at least 1 region!";
      }
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ width: "30%", margin: "0.5%" }}>
        <Typography sx={{fontSize:18,fontWeight:"bold",pb:"10px"}}>Opereator Information</Typography>
          <TextField
            fullWidth
            label="First Name"
            margin="normal"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            error={Boolean(formErrors.firstName)}
            helpertext={formErrors.firstName}
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            error={Boolean(formErrors.lastName)}
            helpertext={formErrors.lastName}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region"
              multiple
              value={formData.regionIds}
              onChange={handleChange}
              name="regionIds"
              required
              error={Boolean(formErrors.regionIds)}
              helpertext={formErrors.regionIds}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '400px',  // Set the maximum height
                    overflow: 'auto',    // Enable scrolling
                  },
                },
              }}
            >
              {regionList &&
                regionList.map((region, index) => (
                  <CustomMenuItem key={index} value={region.id}>
                    {region.name}
                  </CustomMenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Address"
            margin="normal"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Mobile"
            margin="normal"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </Box>



        <Box sx={{ width: "30%", margin: "0.5%" }}>
          <Typography sx={{fontSize:18,fontWeight:"bold",pb:"10px"}}>Opereator Credentitals</Typography>
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={Boolean(formErrors.email)}
            helpertext={formErrors.email}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            error={Boolean(formErrors.password)}
            helpertext={formErrors.password}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            type="password" // For secure text entry
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            error={Boolean(formErrors.confirmPassword)}
            helpertext={formErrors.confirmPassword}
          />
        </Box>

        {/* Rights */}
        <Box sx={{ width: "30%", margin: "0.5%" }}>
        <Typography sx={{fontSize:18,fontWeight:"bold",pb:"10px"}}>Opereator Rights</Typography>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {rightsList && rightsList.map((right) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.rightsIds.includes(right.id)}
                      onChange={handleChange}
                      name="rightsCheckBox"
                      value={right.id}
                      sx={{
                        color: 'lightgray', // Default color
                        '&.Mui-checked': {
                          color: 'limegreen', // Custom color when checked
                        },
                      }}
                    />
                  }
                  label={right.description}
                  key={right.id}
                  //value={right.id}
                  sx={{ my: 1 }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default OperatorForm;
