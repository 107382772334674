import React, { useEffect, useState } from "react";
import { useUpdateUPDvbcMutation } from 'features/userPackets/userPacketsApiSlice';
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";

const EditDvbcForm = ({
    onClose,
    formData,
    refetchList,
    dvbcData,
    userPacketId,
    refetchDetails,
  }) => {

    const [updateDvbcUser] = useUpdateUPDvbcMutation();
  const { showNotification } = useServerResponseNotifier();
  const [formatSerial,setFormatSerial]=useState("");
  const [dvbc, setDvbc] = useState({
    serialNumber: dvbcData?.serialNumber || "",
  });

  useEffect(() => {
    setDvbc({
        serialNumber: dvbcData?.serialNumber || "",
    });
  }, [dvbcData]);


  const formatSerialNumber = (value) => {
    // Remove all non-digit characters
    let newValue = value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);

    // Insert hyphens at the correct positions
    if (newValue.length > 3) newValue = newValue.slice(0, 3) + "-" + newValue.slice(3);
    if (newValue.length > 7) newValue = newValue.slice(0, 7) + "-" + newValue.slice(7);
    if (newValue.length > 11) newValue = newValue.slice(0, 11) + "-" + newValue.slice(11);

    return newValue;
  };


  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
   const formattedValue = formatSerialNumber(value);
   setFormatSerial(formattedValue);
    
    setDvbc((prevData) => ({
      ...prevData,
      [name]: formattedValue.replace(/\D/g, ""),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
      ...dvbc,
    };
    console.log(updatedData);

    await updateDvbcUser({
      id: userPacketId,
      request: updatedData,
    }).unwrap()
      .then(() => {
        showNotification("Service for user updated successfully!", 200);
        refetchDetails();
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

    return (
        <Box paddingTop="20px">
          <TextField
            fullWidth
            label="Serial Number"
            margin="normal"
            name="serialNumber"
            value={formatSerialNumber(dvbc.serialNumber)}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
          <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
        </Box>
      );
    };

export default EditDvbcForm