import { useLocation, Navigate, Outlet, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";

import React from "react";
import { getClaimByKey } from "utils/jwtUtils";
import OperatorLayout from "scenes/layout/OperatorLayout";
import Layout from "scenes/layout";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken) || localStorage.getItem('accessToken');
 //  
  const role = getClaimByKey("role")??[];
  const rights=getClaimByKey("Rights");
  const location = useLocation();

  const allowedRoutesForRole = {
    Admin: ["/", 
    "/dashboard"
    ,"/services"
    ,"/packets"
    ,"/operators"
    ,"/users"
    ,"/offers"
    ,"/withdrawals"
    ,"/regions"
    ,"/services/add-service"
    ,"/payments/add-payment"
    ,"/payments"
    ,"/payments/add-payment/:id"
    ,"/print"
  ],
    Operator: ["/"
    ,"/dashboard"
    ,"/payments/add-payment"
    ,"/payments"
    ,"/payments/add-payment/:id"
    ,"/print"
  ]
  };

  if(rights?.includes("ManageUsers"))
  {
    allowedRoutesForRole.Operator.push("/users");
  }

  if(rights?.includes("ManageServices"))
  {
    allowedRoutesForRole.Operator.push("/services/add-service");
  }

  const isPathMatch = (path) => {
    if (path.includes(":id") && location.pathname.startsWith(path.split(":id")[0])) {
      return true;
    }
    return location.pathname === path;
  };

  if (!token) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  if (!allowedRoutesForRole[role].some(isPathMatch)) {
    return <Navigate to="/dashboard" replace />;  // Redirect to a default page if not authorized
  }

  return role === 'Admin' ? <Layout /> : <OperatorLayout />;
};

export default RequireAuth;
