import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';
import React, { useEffect, useState } from 'react'
import { TextField, Box } from "@mui/material";
import CloseAddFooter from '../CloseAddFooter';
import { useCreateExtreamUserMutation } from 'features/userPackets/userPacketsApiSlice';


const CreateExtreamForm= ({ onClose, formData, currentUser,refetchList }) => {
  const { showNotification } = useServerResponseNotifier();
  const [createExtreamUser]=useCreateExtreamUserMutation();
  const [username,setUsername]=useState("");

  const [extreamData, setExtreamData] = useState({
    username: "",
    password:"1234",
    description: "",
    macAddress:"",
  });

  const [dataForUpdate, setDataUpdate] = useState({
    ...formData,
    ...extreamData,
  });

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...extreamData,
      ["username"]:username,
    });
  }, [extreamData]);
  
  useEffect(() => {
    const generatedUsername = generateData();
    setUsername(generatedUsername);
  }, [extreamData]);


  const handleChange = async (e) => {
    const { name, value } = e.target;

      if(name==="macAddress")
      {
        let newValue=value;
        newValue=newValue.toUpperCase();
        newValue = newValue.replace(/:/g, '');
        newValue = newValue.slice(0, 12);
        newValue = newValue.match(/.{1,2}/g)?.join(':') || '';
        setExtreamData({
          ...extreamData,
          [name]: newValue,
        });
        return;
      }

    setExtreamData({
      ...extreamData,
      [name]: value,
    });
  };


  const generateData = () => {
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await createExtreamUser(dataForUpdate).unwrap()
    .then(showNotification("Service for user created succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
    <TextField
      fullWidth
      defaultValue={generateData()}
      label="Username"
      margin="normal"
      name="username"
      onChange={handleChange}
    />

<TextField
        fullWidth
        defaultValue="1234"  // krijo logjiken qe ta marre nga default config passwordin
        label="Password"
        margin="normal"
        name="password"
        variant="outlined" 
        onChange={handleChange}
      />
    <TextField
      fullWidth
      label="MAC"
      margin="normal"
      name="macAddress"
      value={extreamData.macAddress}
      onChange={handleChange}
    />
    <TextField
      fullWidth
      label="Description"
      margin="normal"
      name="Description"
      onChange={handleChange}
    />

    <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
  </Box>
  )
}

export default CreateExtreamForm