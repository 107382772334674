import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateStalkerUserMutation } from "features/userPackets/userPacketsApiSlice";

const CreateStalkerForm = ({ onClose, formData, currentUser,refetchList }) => {
  const { showNotification } = useServerResponseNotifier();
  const [createStalkerUser]=useCreateStalkerUserMutation();
  const [username,setUsername]=useState("");

  const [stalkerData, setStalkerData] = useState({
    username: "",
    description: "",
    macAddress:"",
  });

  const [dataForUpdate, setDataUpdate] = useState({
    ...formData,
    ...stalkerData,
  });

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...stalkerData,
      ["username"]:username,
    });
  }, [stalkerData]);
  
  useEffect(() => {
    const generatedUsername = generateData();
    setUsername(generatedUsername);
  }, [stalkerData]);



  const handleChange = async (e) => {
    const { name, value } = e.target;


    if(name==="macAddress")
    {
     //  
      let newValue=value;
      newValue=newValue.toUpperCase();
      newValue = newValue.replace(/:/g, '');
      newValue = newValue.slice(0, 12);
      newValue = newValue.match(/.{1,2}/g)?.join(':') || '';
      setStalkerData({
        ...stalkerData,
        [name]: newValue,
      });
      return;
    }

    setStalkerData({
      ...stalkerData,
      [name]: value,
    });
  };


  const generateData = () => {
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await createStalkerUser(dataForUpdate).unwrap()
    .then(showNotification("Service for user created succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

    await onClose();
    await refetchList();
  };
  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        defaultValue={generateData()}
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="MAC"
        margin="normal"
        name="macAddress"
        value={stalkerData.macAddress}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="Description"
        onChange={handleChange}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default CreateStalkerForm;
