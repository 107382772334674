import React, { useEffect, useState } from "react";
import { Box, IconButton, TablePagination, useTheme,Button } from "@mui/material";
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from "@mui/x-data-grid";
import AddNewHeader from "components/forms/headers/AddNewHeader";
import OperationHeader from "./forms/headers/HeaderWithSearch";

const PaginationTable = ({
  data,
  isLoading,
  columns,
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  fixedMinWith
}) => {
  const theme = useTheme();


  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const changePagination= (e)=>{
    setPaginationModel(e);
    onPageChange(e);
  }

  useEffect(()=>{
      setPaginationModel({
        page: currentPage,
       pageSize: pageSize
      });
  },[currentPage,pageSize]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <Box
        mt="40px"
        height="75vh"
        minWidth={fixedMinWith?fixedMinWith:"auto"}
        overflow="auto"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerConainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data || []}
          columns={columns}
          pagination
          rowSelection={false}
          paginationModel={paginationModel}
          pageSizeOptions={[5,10,50,100]}
          rowCount={totalCount||0}
          paginationMode="server"
          onPaginationModelChange={changePagination}
          loading={isLoading}
          keepNonExistentRowsSelected
          columnVisibilityModel={{
            id: false,
          }}
          fixedMinWith={"1200px"}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default PaginationTable;
