import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import axios from "axios";
import { useCreateUserMutation } from "features/users/usersApiSlice";
import { useSelector } from "react-redux";
import { getClaimByKey } from "utils/jwtUtils";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import { useGetAutocompleteRegionsListQuery } from "features/autocomplete/autocompleteApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "components/styled/CustomMenuItem";


const UsersForm = ({onClose,refetch}) => {
  const {showNotification}=useServerResponseNotifier();
 // const [regions, setRegions] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    regionId: 0,
    operatorId: 0,
    email: "",
    address: "",
    city: "",
    phoneNumber: "",
  });
  const [createUser, { isLoading, isError, isSuccess }] =useCreateUserMutation();
  //const { data: regions, isLoading: isRegionLoading, refetch: refetchRegions } = useGetRegionListQuery();


  
  const { data: regions, isLoading: isRegionLoading,refetch: refetchRegions } =   useGetAutocompleteRegionsListQuery();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    formData.operatorId = getClaimByKey("Id");

    await createUser(formData)
      .unwrap()
      .then(showNotification("User created succesfully!",200))
      .catch((error) => {
        showNotification(error.data.Message,error.data.StatusCode)
      });

      await onClose();
      await refetch();
  };

  useEffect( () => {
     refetchRegions();
  }, []);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="First Name"
        margin="normal"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="normal"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="region-label">Region</InputLabel>
        <Select
          labelId="region-label"
          name="regionId"
          value={formData.regionId}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px',  
                overflow: 'auto',  
              },
            },
          }}
        >
          {regions && regions.map((region, index) => (
            <CustomMenuItem key={index} value={region.id}>
              {region.value}
            </CustomMenuItem>
          ))}
        </Select>
      </FormControl>

      
      <TextField
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Address"
        margin="normal"
        name="address"
        value={formData.address}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="City"
        margin="normal"
        name="city"
        value={formData.city}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="phoneNumber"
        margin="normal"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
      />


        <Box
          sx={{
            marginTop: "1em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="secondary" onClick={()=>{
            onClose();
          }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Box>
     
    </Box>
  );
};

export default UsersForm;
