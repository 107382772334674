import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import {
  useDeleteRegionMutation,
  useGetRegionListQuery,
} from "features/regions/regionsApiSlice";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const DeleteRegionForm = ({ onClose, region, refetch }) => {
  const [formData, setFormData] = useState({
    oldRegionId: region.row.id,
    newRegionId: null,
  });

  const { data: regions, isLoading: isRegionLoading } = useGetRegionListQuery();
  const { showNotification } = useServerResponseNotifier();
  const [deleteRegion] = useDeleteRegionMutation();

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await deleteRegion({
      oldRegionId: formData.oldRegionId,
      newRegionId: formData.newRegionId,
    })
      .unwrap()
      .then(() => {
        showNotification("Region deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography sx={{ fontSize: "23px", fontWeight: "bold" }}>
          YOU ARE DELETING THE REGION: {region.row.name}
        </Typography>{" "}
      </Box>

      <Box display={"flex"} justifyContent={"center"} pt={"15px"}>
        <Typography sx={{ fontSize: "20px" }}>
          This region has{" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {region.row.numberOfUsers}
          </span>{" "}
          users!
        </Typography>
      </Box>



        <Box
          display={region.row.numberOfUsers > 0 ? "flex" : "none"}
          alignItems="center"
          justifyContent="space-between"
          pt={"20px"}
        >
         <Typography sx={{ fontSize: "20px", pt: "40px" }}>
          Select a region you want to transfer the users.
        </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "line-through",
            }}
          >
            {region.row.name}
          </Typography>

          <TrendingFlatIcon sx={{ fontSize: "60px" }} />

          <FormControl sx={{ m: 0, minWidth: 150, fontSize: "20px" }}>
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              name="newRegionId"
              //value={formData.regionId}
              onChange={handleChange}
              sx={{ fontSize: "20px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflow: "auto",
                  },
                },
              }}
            >
              {regions &&
                regions
                  .filter((reg) => reg.id !== region.row.id)
                  .map((reg, index) => (
                    <MenuItem key={index} value={reg.id}>
                      {reg.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeleteRegionForm;
