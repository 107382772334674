import { Box,TextField } from "@mui/material";
import React from "react";
import CloseAddFooter from "./CloseAddFooter";
import { useCreateRegionMutation } from "features/regions/regionsApiSlice";
import { useState } from "react";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";


const RegionForm = ({onClose,refetchRegions}) => {
    const [formData, setFormData] = useState({
        name: ""
    });
    const { showNotification } = useServerResponseNotifier();

    const [createRegion, { isLoading, isError, isSuccess }] =useCreateRegionMutation();

    const handleSubmit =async (e) => {
        e.preventDefault();
       await createRegion(formData)
          .unwrap()
          .then(() => {
          showNotification("Region created succesfully",201);
          })
          .catch((error) => {
            showNotification(error.data.Message,error.data.StatusCode);
          });
          await refetchRegions();
          onClose();
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };


  return (
    <Box>
      <TextField
        fullWidth
        label="Region Name"
        margin="normal"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />

      <CloseAddFooter onClose={onClose}  onClick={handleSubmit}/>
    </Box>
  );
};

export default RegionForm;
