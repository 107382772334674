import React from 'react'
import { useDeletePaymentMutation } from 'features/payments/paymentsApiSlice';
import {
    Box,
    Stack,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
  } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';

const DeletePaymentForm = ({ onClose, payment, refetch }) => {
    const [deletePayment] = useDeletePaymentMutation();
    const { showNotification } = useServerResponseNotifier();
  
  const handleSubmit = async () => {
    await deletePayment(payment.id)
      .unwrap()
      .then(() => {
        showNotification("Payment deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };
    return (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack alignItems="center">
              <Typography fontSize="20px">
                Are you sure you want to delete this payment?
              </Typography>
              <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
                {payment.name}
              </Typography>
            </Stack>
          </Box>
    
          <CloseAddFooter
            footerLabel={"DELETE"}
            onClose={onClose}
            onClick={handleSubmit}
          />
        </Box>
      );
    };
    

export default DeletePaymentForm