import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateDvbcUserMutation } from "features/userPackets/userPacketsApiSlice";

const CreateDvbcForm = ({ onClose, formData, currentUser, refetchList }) => {
  const { showNotification } = useServerResponseNotifier();
  const [createDvbcUser] = useCreateDvbcUserMutation();
  const [formatSerial,setFormatSerial]=useState(null);
  const [dvbcData, setDvbcData] = useState({
    serialNumber: "",
  });

  const [dataForUpdate, setDataUpdate] = useState({
    ...formData,
    ...dvbcData,
  });

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...dvbcData,
    });
  }, [dvbcData]);


  const formatSerialNumber = (value) => {
    // Remove all non-digit characters
    let newValue = value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);

    // Insert hyphens at the correct positions
    if (newValue.length > 3) newValue = newValue.slice(0, 3) + "-" + newValue.slice(3);
    if (newValue.length > 7) newValue = newValue.slice(0, 7) + "-" + newValue.slice(7);
    if (newValue.length > 11) newValue = newValue.slice(0, 11) + "-" + newValue.slice(11);

    return newValue;
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
   const formattedValue = formatSerialNumber(value);
   setFormatSerial(formattedValue);
    setDvbcData({
      ...dvbcData,
      [name]:  formattedValue.replace(/\D/g, ""),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createDvbcUser(dataForUpdate)
      .unwrap()
      .then(showNotification("Service for user created succesfully!", 200))
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        label="Serial Number"
        margin="normal"
        name="serialNumber"
        value={formatSerial}
        onChange={handleChange}
      />
      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};


export default CreateDvbcForm;
