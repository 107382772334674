import React, { useState,useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import EditCancelFooter from "../footers/EditCancelFooter";
import { useUpdateRegionMutation } from "features/regions/regionsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const EditRegionForm = ({ onClose, region,refetch }) => {
  const [regionName, setRegionName] = useState("");
  const [updateRegion] = useUpdateRegionMutation();
  const { showNotification } = useServerResponseNotifier();

  const handleUpdateRegion = async (e) => {
    e.preventDefault();
    const updatedRegion=  await updateRegion({ id: region.id, name: regionName })
    .unwrap()
    .then(async ()=>{
      await refetch();
      showNotification("Region updated !",200)
    })
    .catch((error)=>{
        showNotification(error.data.Message,error.data.StatusCode);
    })

    onClose();
  };

  useEffect(() => {
    setRegionName(region.row.name);
  }, [region]);

  const updateRegionName = (e) => setRegionName(e.target.value);

  return (
    <Box>
      <Box>
        <TextField
          fullWidth
          label="Edit region"
          margin="normal"
          name="editRegion"
          value={regionName}
          onChange={updateRegionName}
        />
      </Box>
      <EditCancelFooter onClose={onClose} onClick={handleUpdateRegion} />
    </Box>
  );
};

export default EditRegionForm;
